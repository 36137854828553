export const KPAYMENT_ADD_NEW_CARD = {

    //PRD
    K_PAYMENT_SCRIPT : 'https://kpaymentgateway.kasikornbank.com/ui/v2/kinlinepayment.min.js',
    K_PAYMENT_PKEY : 'pkey_prod_958OPIZAYnYyaclNGKMQq0nlB3MgRyMWUVF'



    // K_PAYMENT_PKEY : 'pkey_prod_95835O0wXvPYZ4cGz3zcVeh5Jcxkws6LMgR'

    //DEV
    // K_PAYMENT_SCRIPT : 'https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kinlinepayment.min.js',
    // K_PAYMENT_PKEY : 'pkey_test_205986b7TEzmMi0ZTA83JQIwviUVITY2fMfFv'
}