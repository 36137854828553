import React from 'react'
import NotFoundPage from '../pages/NotFound';
import Unauthorized from '../pages/Unauthorized'
import AddNewCardPage from '../pages/AddNewCard'
import { Routes ,Route ,BrowserRouter } from 'react-router-dom';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/addnewcard/:token" element={<AddNewCardPage/>} />
                <Route exact path='/unauthorized' element={<Unauthorized/>}/>
                <Route path='*' element={<NotFoundPage/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router
