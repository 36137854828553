import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import '../../styles/kpayment.scss'

function Unauthorized() {
    const location = useLocation()
    return (
        <Fragment>
            <div className='container-error'>
                <p className="title-statuscode">
                    {location.state.statusCode ? location.state.statusCode: '-'}
                </p>
                <p className="title-message">
                    {location.state.message ? location.state.message : '-'}
                </p>
                <p className="desc">
                    {location.state.message ? location.state.desc : '-'}
                </p>
            </div>
        </Fragment>
    )
}

export default Unauthorized
