import axios from './axios'

export const apiStartCardRegistration = async (data, access_token) => {
    try {
        const result = await axios.post('/private/api/v1/payment/add_card',data,
            { headers: { Authorization: `Bearer ${access_token}` }}
        )
        return result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}

export const apiStartCustomerRegistration = async (data, access_token) => {
    try {
        const result = await axios.post('/private/api/v1/payment/register_customer',data,
            { headers: { Authorization: `Bearer ${access_token}` }}
        )
        return result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
