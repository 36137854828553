import React, { useRef, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import '../../styles/kpayment.scss'
import {  useNavigate , useParams } from 'react-router-dom'
import { KPAYMENT_ADD_NEW_CARD } from '../../config/kpaymentgateway'
import {apiStartCardRegistration, apiStartCustomerRegistration} from '../../services/kpaymentgateway'

const AddNewCard = () =>  {
    const paymentForm = useRef(null)
    const navigate = useNavigate()
    const { token } = useParams()
    const [isNewCustomer, setisNewCustomer] = useState(false)
    const [access_token, setaccess_token] = useState('')
    const [dataStartCard, setdataStartCard] = useState({
        projectName: '',
        projectUserId: '',
        customerId: '',
    })
    
    const [dataStartCustomer, setdataStartCustomer] = useState({
        projectName: '',
        projectUserId: '',
        email: '',
        name: '',
    })


    useEffect(() => {
        checkUserToken()
        const scriptTag = document.createElement('script')
        scriptTag.src = KPAYMENT_ADD_NEW_CARD.K_PAYMENT_SCRIPT
        scriptTag.onload = function () {
            window.KInlineCheckout.configure({ apikey: KPAYMENT_ADD_NEW_CARD.K_PAYMENT_PKEY, lang : 'TH'})
            window.KInlineCheckout.onInputValidation((element, message) => {
                if (message === 'invalid') {
                    document.getElementById(element).classList.add('is-danger')
                } else {
                    document.getElementById(element).classList.remove('is-danger')
                }
            })
        }
        document.body.appendChild(scriptTag)
    }, [])



    const checkUserToken = () => {
        if (token) {
            try {
                const protectToken = jwt_decode(token)
                const userToken = jwt_decode(protectToken.access_token)
                if (protectToken && userToken) {
                    setisNewCustomer(protectToken.newCustomer)
                    setaccess_token(protectToken.access_token)
                    if (protectToken.newCustomer) {
                        setdataStartCustomer({
                            email: protectToken.email,
                            name: protectToken.name,
                            projectName: protectToken.projectName,
                            projectUserId: protectToken.projectUserId,
                        })
                    } else {
                        setdataStartCard({
                            projectName: protectToken.projectName,
                            projectUserId: protectToken.projectUserId,
                            customerId: protectToken.customerId,
                        })
                    }
                }
            } catch (error) {
                navigate('/unauthorized' , {state :{ statusCode: 400, message: 'ไม่สามารถดำเนินการได้' , desc: 'กรุณาลองใหม่ในภายหลัง' }})
            }
        } else {
            navigate('/unauthorized' , {state :{ statusCode: 401, message: 'ไม่สามารถดำเนินการได้' , desc : "กรุณาลองใหม่ในภายหลัง" }})
        }
    }



    const handleSubmit = (event) => {
        event.preventDefault()
        window.KInlineCheckout.getToken()
            .then((result) => {
                if (result.token) {
                    if (isNewCustomer) {
                        let formData = new FormData()
                        formData.append('projectName',dataStartCustomer.projectName)
                        formData.append('projectUserId',dataStartCustomer.projectUserId)
                        formData.append('email', dataStartCustomer.email)
                        formData.append('name', dataStartCustomer.name)
                        formData.append('token', result.token)
                        formData.append('saveCard', result.saveCard)
                        apiStartCustomerRegistration(formData,access_token).then((response) => {
                            if (response.status) {
                                window.location.href = response.result.redirectUrl
                            } else {
                                navigate('/unauthorized' , {state :{ statusCode: 500, message: 'เกิดข้อผิดพลาด', desc : "กรุณาลองใหม่ในภายหลัง"  }})
                            }
                        })
                    } else {
                        let formData = new FormData()
                        formData.append('projectName', dataStartCard.projectName)
                        formData.append('projectUserId', dataStartCard.projectUserId)
                        formData.append('customerId', dataStartCard.customerId)
                        formData.append('token', result.token)
                        apiStartCardRegistration(formData, access_token).then(
                            (response) => {
                                if (response.status) {
                                    window.location.href = response.result.redirectUrl
                                } else {
                                    navigate('/unauthorized' , {state :{ statusCode: 500, message: 'เกิดข้อผิดพลาด' , desc : "กรุณาลองใหม่ในภายหลัง" }})
                                }
                            }
                        )
                    }
                } else {
                    navigate('/unauthorized' , {state :{ statusCode: 401, message: 'ไม่สามารถดำเนินการได้' , desc : "กรุณาลองใหม่ในภายหลัง" }})
                }
            })
            .catch((error) => {
                navigate('/unauthorized' , {state :{ statusCode: 422, message: 'ไม่สามารถดำเนินการได้' , desc : "กรุณาลองใหม่ในภายหลัง"  }})
            })
    }



    return (
        <div className="App">
            <div className="column is-half is-offset-one-quarter">
                <div className="content is-medium">
                    <div className="bd-index-custom-example">
                        <h1 className="title">Please fill your card</h1>
                        <div className="field">
                            <label className="label">Card Number</label>
                            <div className="control">
                                <div id="card-number" className="input"></div>
                                <label>Card number is required.</label>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Card Name</label>
                            <div className="control">
                                <div id="card-name" className="input"></div>
                                <label>Card name is required.</label>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    <label className="label">Card Expiry</label>
                                    <div className="control is-expanded">
                                        <div
                                            id="card-expiry"
                                            className="input"
                                        ></div>
                                        <label>Expiry date is required.</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Card CVV</label>
                                    <div className="control is-expanded">
                                        <div
                                            id="card-cvv"
                                            className="input"
                                        ></div>
                                        <label>CVV is required.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <div id="remember-card"></div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} ref={paymentForm}>
                            <button type="submit" className="button is-primary">
                                <label className="text-save">Save</label>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewCard
