import axios from 'axios'

const instance = axios.create({
    //PRD
    baseURL:  "https://evsp-mobile.pttor.com/api/"

    //DEV
    // baseURL: 'https://dev-evsp-mobile.pttor.com/api',
})

export default instance
